@use "definitions" as *;

.scm-form-row {
    border-top: 1px solid lg-color(--gray-color-5);
    border-bottom: 1px solid lg-color(--gray-color-5);
    display: flex;
    flex-direction: row;
    padding: 6px 0 6px var(--spacing3);
}

.scm-form-label-wrapper {
    width: 326px;
}

.scm-form-label-hint {
    color: lg-color(--gray-color-40);
}

.scm-form-input-wrapper {
    width: 100%;
    &.lg-switch-align {
        display: flex;
        align-items: center;
    }
}

.scm-form-input-with-copy-button-wrapper {
    display: inline-block;
    position: relative;
    width: 70%;
    min-width: 321px;
    max-width: 500px;
    scm-copy-button {
        position: absolute;
        top: 6px;
        right: 6px;
        color: lg-color(--base-60);
    }
    input {
        width: 100%;
    }
}

label {
    height: var(--spacing6);
    line-height: var(--spacing6);
}

.scm-form-label-hint {
    color: lg-color(--gray-color-40);
    padding-right: var(--spacing1);
    line-height: var(--spacing4);
}

.scm-form-input {
    width: 70%;
    min-width: 321px;
    max-width: 500px;
    &-textarea {
        height: var(--spacing9);
    }
}

.asterisk {
    font-size: 18px;
    color: lg-color(--error-color);
}

.scm-form-field-error {
    font-size: 12px;
    color: lg-color(--error-color);
}

.drop-zone {
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing2);
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(var(--gray-color-20));
    border-radius: var(--border-radius);
    background-color: lg-color(--base-05);
    box-sizing: border-box;
    height: 250px;

    &.file-over {
        background: lg-color(--ok-color, 0.2);
        border: dotted 4px lg-color(--ok-color);
        &.no-drop {
            background: lg-color(--error-color, 0.2);
            border-color: lg-color(--error-color);
            cursor: not-allowed;
        }
    }
}
