@import "definitions";
@import "scm-forms";
@import "scm-contract-list";

:root {
    --Status-Error-light: #{hexToLgColor(#fce1e5)};
}

.scm-draft-create-page__wrapper,
.scm-contract-list-page__wrapper {
    flex-grow: 1;
    width: 100%;
    height: calc(100vh - 75px);
    display: flex;
    flex-direction: row;
    column-gap: var(--spacing4);
}

.scm-contract-list-page__wrapper {
    width: 100%;
    height: calc(100vh - 75px);
    overflow: hidden;
}

.lg-breadcrumb {
    container-type: normal;
}

.scm-node-delete-button {
    color: lg-color(--error-color);
    cursor: pointer;
    background: none;
    border-radius: var(--spacing0);
    &:hover {
        background: lg-color(--error-color, 0.05);
    }
}

.contract-edit-area-tabs {
    display: flex;
    flex-direction: row;
    column-gap: var(--spacing0);
}

.simple-table {
    border: 1px solid $gray-color-20;
    border-radius: $border-radius;
    height: 60vh;
}
