@import "definitions";

.scm-contract-list {
    &__info-icon {
        color: lg-color(--gray-color-25);
        padding-top: 2px;
    }
    &__update-icon {
        color: lg-color(--warning-color);
        padding-top: 4px;
        margin-left: 4px;
    }
    &__text-with-icon {
        display: flex;
        flex-direction: row;
    }
    &__view-link {
        display: none;
    }
    &__name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &__errors {
        color: lg-color(--error-color);
    }
}

.lg-pivot-table__row:hover {
    & .scm-contract-list__name {
        max-width: calc(100% - var(--spacing4));
    }
    & .scm-contract-list__info-icon {
        color: lg-color(--base-60);
    }
}

.lg-sort-by-column__indicator {
    top: -5px;
}

.no-results {
    text-align: center;
    padding: var(--spacing5);
}

.table-row--selected {
    background: lg-color( --ok-color, 0.2);
    &:hover {
        background: lg-color( --ok-color, 0.3);
    }
}

.inline-into-with-copy-button {
    display: flex;
    flex-direction: row;
    column-gap: var(--spacing0);
}

.scm-copy-button-link {
    color: lg-color(--white-color);
    cursor: pointer;
    &:hover {
        color: lg-color(--white-color);
    }
}
