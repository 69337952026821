/* You can add global styles to this file, and also import other style files */
@import "~@angular/cdk/overlay-prebuilt.css";

@import "assets/styles/main.scss";
@import "definitions";

.app-icon {
    background: url("/data-platform.svg") 50% 50% no-repeat;
    margin-top: $spacing1;
}

.align-center {
    align-self: center;
}

.top-button {
    margin-left: var(--spacing1);
}

.warning-text {
    color: lg-color(--warning-color);
    font-size: 13px;
}

.error-text {
    color: lg-color(--error-color);
    font-size: 13px;
}

.lg-dropdown,
textarea,
lg-switch,
.lg-multi-filter__option-list__item {
    color: var(--gray-color-80) !important;
}
